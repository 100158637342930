import React from 'react'
import Link from 'next/link'
import { useTranslation } from 'next-i18next'
import Logo from '../components/elements/Logo'
import NoNavLayout from '../layouts/NoNavLayout'
import { routes } from '../utils/routes'
import { GetStaticProps } from 'next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'

export const getStaticProps: GetStaticProps = async ({ locale }) => {
    return {
        props: {
            ...(await serverSideTranslations(locale ?? 'en', ['common'])),
        },
    }
}

const FourOhFour: React.FC = () => {
    const { t } = useTranslation()

    return (
        <NoNavLayout>
            <div className="h-screen">
                <div className="flex flex-col min-h-full bg-white lg:relative">
                    <div className="flex flex-col flex-grow">
                        <main className="flex flex-col flex-grow bg-white">
                            <div className="flex flex-col flex-grow px-4 mx-auto w-full max-w-7xl sm:px-6 lg:px-8">
                                <div className="flex-shrink-0 pt-10 sm:pt-16">
                                    <Link href="/">
                                        <a>
                                            <Logo className="inline-block" />
                                        </a>
                                    </Link>
                                </div>
                                <div className="flex-shrink-0 py-16 my-auto sm:py-32">
                                    <p className="text-sm font-semibold tracking-wide text-gray-500 uppercase">
                                        {t('404-subtitle')}
                                    </p>
                                    <h1 className="mt-2 text-4xl font-extrabold tracking-tight sm:text-5xl">
                                        {t('404-title')}
                                    </h1>
                                    <p className="mt-2 text-base text-gray-500">{t('404-message')}</p>
                                    <div className="mt-6">
                                        <Link href={routes.index.path}>
                                            <a className="text-base font-medium">
                                                <span>{t('back-home')}</span>
                                                <span aria-hidden="true"> &rarr;</span>
                                            </a>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </main>
                    </div>
                    <div className="hidden overflow-hidden pointer-events-none lg:block lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
                        <div className="bg-mariner-200 w-full h-full" />
                    </div>
                </div>
            </div>
        </NoNavLayout>
    )
}

export default FourOhFour
